.main {
  width: 340px;
  height: 50px;
  background-color: rgba(85, 80, 79, 1);
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.iconDIv {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

.signinText {
  color: #fffff2;
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  margin-left: 75px;
}

.emailAndPasswordbUtton {
  width: 340px;
  height: 50px;

  border: 0;
}
