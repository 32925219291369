.main {
  width: 100dvw;
  height: 100dvh;

  background-image: url("/app-index-photos/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper {
  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, rgba(24, 23, 22, 0) 0%, #181716 100%);

  display: flex;
  flex-direction: column;

  padding-bottom: 50px;
}

.stepOneTopDiv {
  background-color: #181716;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  background-image: url("/app-index-photos/stepOneBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.stepTwoTopDiv {
  background-color: #181716;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  background-image: url("/app-index-photos/stepTwoBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.stepThreeTopDiv {
  background-color: #181716;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  background-image: url("/app-index-photos/stepThreeBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.stepFourTopDiv {
  background-color: #181716;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  background-image: url("/app-index-photos/stepFourBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.buttonDiv {
  margin-top: auto;

  align-self: center;
}

.button {
  width: 315px;
  height: 49px;

  border: 0;
  border-radius: 7px;

  background: var(--5---Cream-white, #fffff2);
  backdrop-filter: blur(13.591408729553223px);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.circleDiv {
  margin-top: 27px;
  margin-bottom: 51px;

  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;
}

.circleActive {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  border-radius: 50%;
  margin-right: 5px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  opacity: 0.35;
  border-radius: 50%;
  margin-right: 5px;
}

.buttonsDiv {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 340px;

  min-width: 100%;
}

.buttonsDivPeer {
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 340px;

  min-width: 100%;
}

.verifiedButtonLink {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 12px;
  font-weight: 700;

  margin-top: 25px;
}

.peerAssessmentOnboardingImage {
  align-self: center;

  margin-top: 129px;
}

.peerTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 30px;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -0.3px;

  margin-top: 100px;
}

.peerSubtitle {
  color: var(--35-text, #c0b9b7);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;

  margin-top: 10px;
}
